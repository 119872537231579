/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Hoy he instalado mi webcam en Debian, la primera vez que lo intenté acabé formateando y reinstalando el SO, pero hoy ya llevaba las cosas claras, vamos por pasos."), "\n", React.createElement(_components.p, null, "Primero hay que saber que Cam tienes y aunque parezca una tonteria esto es muy importante porque mirando por la caja, por las instrucciones y dandole vueltas a la Cam no encontre ningún sitio donde pusiera el modelo de la circuiteria de la camara, así que la conecte al ordenador y puse en la consola:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\">lsusb</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Este comando te lista(ls) los dispositivos USB que tengas conectados. Lo que me respondio fue esto:"), "\n", React.createElement(_components.p, null, "Bus 002 Device 001: ID 0000:0000 Bus 004 Device 002: ID 0c45:613c Microdia Bus 004 Device 001: ID 0000:0000 Bus 003 Device 001: ID 0000:0000 Bus 001 Device 001: ID 0000:0000"), "\n", React.createElement(_components.p, null, "Ahora ya sabemos cual es el modelo de nuestra Cam,ID 0c45:613c Microdia. Busqué información sobre este modelo en el google y encontré que el driver para esta camara es el gspca, un modulo del kernel. Asíque antes de ponerme a bajar drivers inutiles mire la versión de mi kernel."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">uname</span> <span class=\"token parameter variable\">-r</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "2.6.18-5-686"), "\n", React.createElement(_components.p, null, "Y me bajé el módulo correspondiente a mi versión de kernel con el synaptic, también se puede hacer con el apt pero te tienes que asegurar de que existe esa versión."), "\n", React.createElement(_components.p, null, "Luego me instalé el xawtv y pude comprobar que me iba perfectamente aunque eso si con un poco menos de calidad que en windows."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
